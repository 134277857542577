$primaryColor: var(--primary-color);

.tripNameCell {
	font-size: 14px;
}

/* link */
.tripNameCell a:link,
.tripNameCell a:visited,
.tripNameCell a:hover,
.tripNameCell a:active {
	color: var(--primary-color);
	text-decoration: none;
}
